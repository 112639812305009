import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
Vue.config.productionTip = false;

Vue.prototype.$showMessage = (type='success', text='success') => {
  console.log(store.state.messageOptions.visible, type, text)
  if (store.state.messageOptions.visible) return
  let option = {
    visible: true,
    type,
    text
  }
  store.commit('updateMessageOptionsMutation', option)
  let msgTimer = setTimeout(() => {
    clearTimeout(msgTimer)
    store.commit('updateMessageOptionsMutation', {visible: false})
  }, 3 * 1000)
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
