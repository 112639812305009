import Vue from 'vue'
import VueRouter from 'vue-router'

// import Home from '../views/Home.vue';


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import(/* webpackChunkName: "home" */ '../views/index/index'),
    meta: {
      title: 'PLAY ORDZ'
    }
  },
  {
    path: '/inscription/:hash',
    name: 'Inscription',
    component: () => import(/* webpackChunkName: "inscription" */ '../views/inscription/index'),
    meta: {
      title: 'PLAY ORDZ'
    }
  },
  {
    path: '/ordz-heroes',
    name: 'OrdzHeroes',
    component: () => import(/* webpackChunkName: "ordz-heroes" */ '../views/inscription/index'),
    meta: {
      title: 'ORDZ HEROES'
    }
  },
  {
    path: '/ordz-pk',
    name: 'OrdzPk',
    component: () => import(/* webpackChunkName: "ordz-pk" */ '../views/inscription/index'),
    meta: {
      title: 'ORDZ PK'
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
