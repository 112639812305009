<template >
  <div id="app">
    <router-view/>

    <!-- Message -->
    <div class="common-component-msg-box">
      <div class="common-component-msg-box-content" :class="{'show': messageOptions.visible, 'error': messageOptions.type === 'error'}"> <span>{{ messageOptions.type === 'error' ? '✖' : '✓' }}</span> {{ messageOptions.text }}</div>
    </div>

  </div>
</template>

<script>
// Vuex
import { mapState } from "vuex"


export default {
  data () {
    return {}
  },
  computed: {
    ...mapState(['messageOptions'])
  },
  mounted() {
    let facility_Model = navigator.userAgent.toLocaleLowerCase()
    let isMobile = facility_Model.match(/mobi/i) || facility_Model.match(/android/i) ||  facility_Model.match(/iphone/i);
    window.isMobile = isMobile;
    if(isMobile){
      const windowHeight = window.innerHeight;
      document.querySelector('body').style.height = windowHeight + 'px';
    }
  }
}

</script>

<style lang="scss">
  // reset
  @import './assets/css/reset.scss';
  // reset
  @import './assets/css/common.scss';

  // iconfont
  @import './assets/iconfont/iconfont.css';
</style>
