import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {

    messageOptions: { // message 弹窗
      visible: false, // 控制 message 显示隐藏
      type: null, // success error
      text: '' // success error
    },

    onGamePingMessage: false, //收到游戏ping
    sendGameWallet: false, //发送给游戏钱包信息

    connectWalletPublicKey: '',  // 链接钱包的公钥（用于签名验证）
    connectWalletVerifyAddress: '',   // 链接钱包的地址（用于签名验证 ==> xverse 钱包才需要）

    connectWalletInfo: { // 连接的钱包信息
      type: null, // 钱包类型 xverse unisat
      address: '', // 钱包地址
      balance: 0, // 钱包余额
      code: '', // 邀请码
      email: '', // 邮箱
      depositNumber: 0 // deposit og token number
    },
    connectWalletState: 'not', // 连接状态 not-未连接 connecting-连接中 connected-已连接
  },
  mutations: {

    updateConnectWalletVerifyAddress (state, address) {
      state.connectWalletVerifyAddress = address
    },

    updateConnectWalletPublicKey (state, key) {
      state.connectWalletPublicKey = key
    },

    updateConnectWalletStateMutation (state, val) {
      state.connectWalletState = val
    },

    updateMessageOptionsMutation (state, option) {
      state.messageOptions = {
        ...state.messageOptions,
        ...option
      }
    },

    updateOnGamePingMessageMutation (state, option) {
      state.onGamePingMessage = option
    },

    updateSendGameWalletMutation (state, option) {
      state.sendGameWallet = option
    },

    updateConnectWalletInfoMutation (state, info) {
      state.connectWalletInfo = {
        ...state.connectWalletInfo,
        ...info
      }
    }

  }
})
